import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import { useTranslation } from "react-i18next"

const { getItemLocalStorage } = require("../../scripts/Utils")

import DeleteIcon from "../../svg/delete-icon.inline.svg"

import ListIcon from "../../svg/list-view-icon.inline.svg"
import {
  addSpaceNumber,
  changeTitleRout,
  addSpaceNumberRegEx,
} from "../../scripts/Utils"
import axios from "../../http/axios"

import AppMap from "../map/AppMap"

const UserProfileCompareSchoolsMapSection = () => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const [allItemLocalstorage, setAllItemLocalstorage] = useState([])
  const [allItemSummerLocalstorage, setAllItemSummerLocalstorage] = useState([])

  const w = "40vw"
  const h = "50vw"

  useEffect(() => {
    const getLocalStorageItems = getItemLocalStorage("compare")
    if (getLocalStorageItems) {
      getLocalStorageItems.map(item => {
        axios
          .get(`/schools/${item.id}?lang=${lang}`)
          .then(res => {
            setAllItemLocalstorage(prev => [...prev, res.data])
          })
          .catch()
      })
    }
    const getSummerLocalStorageItems = getItemLocalStorage("compare-summer")
    if (getSummerLocalStorageItems) {
      getSummerLocalStorageItems.map(item => {
        axios
          .get(`/summer-schools/${item.id}?lang=${lang}`)
          .then(res => {
            setAllItemSummerLocalstorage(prev => [...prev, res.data])
          })
          .catch()
      })
    }
  }, [])

  allItemLocalstorage.sort((a, b) => a.name?.localeCompare(b.name))
  allItemSummerLocalstorage.sort((a, b) => a.name?.localeCompare(b.name))

  const handleRemoveItem = (itemId, index) => {
    let array = [...allItemLocalstorage]
    let resultArray = array.filter((_, i) => i !== index)
    setAllItemLocalstorage(resultArray)
    localStorage.setItem("compare", JSON.stringify(resultArray))
  }

  const handleRemoveItemSummer = (itemId, index) => {
    let array = [...allItemSummerLocalstorage]
    let resultArray = array.filter((_, i) => i !== index)
    setAllItemSummerLocalstorage(resultArray)
    localStorage.setItem("compare-summer", JSON.stringify(resultArray))
  }

  const testArrayFromSummer = allItemSummerLocalstorage.map(el => {
    return el.studyCenters
  })

  let resultSummersArray = []
  function resolveStudyCentresTitle(el) {
    for (let localStorage of allItemSummerLocalstorage) {
      if (localStorage.studyCenters.includes(el)) {
        return localStorage.name
      }
    }
  }

  testArrayFromSummer.forEach(studyCentersArray => {
    studyCentersArray.forEach(studyCenter => {
      resultSummersArray.push({
        longitude: studyCenter.latitude,
        latitude: studyCenter.longitude,
        name: resolveStudyCentresTitle(studyCenter),
      })
    })
  })

  const processEdProgrammes = item => {
    let arr
    if (item && item.educationalProgramsResolved) {
      arr = item.educationalProgramsResolved?.split(", ")
      arr.splice(0, 3)
    }
    return arr.length
  }

  function resolveSchoolFees(item) {
    if (
      item.perTermMin !== item.perTermMax &&
      item.perTermMin != null &&
      item.perTermMax != null
    ) {
      return (
        <div className={`saved-school-price`}>
          £{addSpaceNumberRegEx(item.perTermMin)} –{" "}
          {addSpaceNumberRegEx(item.perTermMax)}
        </div>
      )
    } else {
      return (
        <div className={`saved-school-price`}>
          £{addSpaceNumberRegEx(item.perTermMin || item.perTermMax)}
        </div>
      )
    }
  }

  function resolveSummerSchoolFees(item) {
    if (
      item.weeklyFeeMin !== item.weeklyFeeMax &&
      item.weeklyFeeMin != null &&
      item.weeklyFeeMax != null
    ) {
      return (
        <div className={`saved-school-price`}>
          £{addSpaceNumberRegEx(item.weeklyFeeMin)} –{" "}
          {addSpaceNumberRegEx(item.weeklyFeeMax)}
        </div>
      )
    } else {
      return (
        <div className={`saved-school-price`}>
          £{addSpaceNumberRegEx(item.weeklyFeeMin || item.weeklyFeeMax)}
        </div>
      )
    }
  }

  const checkSchoolsPresence = () => {
    if (allItemLocalstorage.length > 0) {
      return (
        <div className="location-comparison-content-wrapper row">
          <div className="school-items-map-view-wrapper col-12 col-md-12 col-xxl-6">
            {allItemLocalstorage &&
              allItemLocalstorage.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className="school-item-comparison-wrapper f-jb"
                  >
                    <Link
                      to={`/${lang}/school/${changeTitleRout(item.engName)}`}
                    >
                      <div className="school-item-comparison-image-description gap-3 f-js">
                        <div className="school-comparison-image-wrapper f-ac">
                          <img
                            src={item.resolvedImageUrl}
                            alt=""
                            className="school-comparison-image"
                          />
                        </div>
                        <div className="school-comparison-main-info">
                          <div>
                            <div className="saved-school-text d-flex align-baseline">
                              <span
                                title={item.schoolTypesResolved}
                                className="school-type-inline-element"
                              >
                                {item.schoolTypesResolved}
                              </span>
                            </div>
                            <div className="saved-school-text">
                              {item.ageMin}–{item.ageMax} {t("school.years")}
                            </div>
                            <div className="saved-school-title">
                              {item.name}
                            </div>
                            <div className="saved-school-text">
                              {item.regionResolved} · {item.settlement}
                            </div>
                          </div>
                          <div className="saved-school-text school-levels">
                            {lang === "ru" || lang === "uk" ? (
                              <>
                                {item.educationalProgramsResolved &&
                                item.educationalProgramsResolved.split(", ")
                                  .length > 3
                                  ? item.educationalProgramsResolved.split(
                                      ", "
                                    )[0] +
                                    ", " +
                                    item.educationalProgramsResolved.split(
                                      ", "
                                    )[1] +
                                    ", " +
                                    item.educationalProgramsResolved.split(
                                      ", "
                                    )[2] +
                                    " " +
                                    t("search.andMore1") +
                                    " " +
                                    t("search.andMore2") +
                                    " " +
                                    processEdProgrammes(item)
                                  : item.educationalProgramsResolved}
                              </>
                            ) : (
                              <>
                                {item.educationalProgramsResolved &&
                                item.educationalProgramsResolved.split(", ")
                                  .length > 3
                                  ? item.educationalProgramsResolved.split(
                                      ", "
                                    )[0] +
                                    ", " +
                                    item.educationalProgramsResolved.split(
                                      ", "
                                    )[1] +
                                    ", " +
                                    item.educationalProgramsResolved.split(
                                      ", "
                                    )[2] +
                                    " " +
                                    t("search.andMore1") +
                                    " " +
                                    item.educationalProgramsResolved
                                      .split(", ")
                                      .splice(0, 3).length +
                                    " " +
                                    t("search.andMore2")
                                  : item.educationalProgramsResolved}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="school-comparison-price price1">
                      <DeleteIcon
                        onClick={() => handleRemoveItem(item, index)}
                        className="delete-icon"
                      />
                      {item.perTermMin && item.perTermMin > 0
                        ? resolveSchoolFees(item)
                        : null}
                    </div>
                  </div>
                )
              })}
          </div>

          <div className="map-view-wrapper col-12 col-xxl-6">
            <div className="map-frame-wrapper d-flex justify-content-center">
              <AppMap width={w} height={h} items={[allItemLocalstorage]} />
            </div>
          </div>
        </div>
      )
    } else {
      return null
    }
  }

  const checkSummerSchoolsPresence = () => {
    if (allItemSummerLocalstorage.length > 0) {
      return (
        <div className="location-comparison-content-wrapper row">
          <div className="school-items-map-view-wrapper col-12 col-md-12 col-xxl-6">
            {allItemSummerLocalstorage &&
              allItemSummerLocalstorage.map((item, index) => {
                return (
                  <div
                    key={item.id}
                    className="school-item-comparison-wrapper f-jb"
                  >
                    <Link
                      to={`/${lang}/summer-schools/${changeTitleRout(
                        item.engName
                      )}`}
                    >
                      <div className="school-item-comparison-image-description gap-3 f-js">
                        <div className="school-comparison-image-wrapper f-ac">
                          <img
                            src={item.resolvedImageUrl}
                            alt=""
                            className="school-comparison-image"
                          />
                        </div>
                        <div className="school-comparison-main-info">
                          <div>
                            <div className="saved-school-text d-flex align-baseline">
                              <span
                                title={item.schoolTypesResolved}
                                className="school-type-inline-element"
                              >
                                {item.schoolTypesResolved}
                              </span>
                            </div>
                            <div className="saved-school-text">
                              {item.ageMin}–{item.ageMax} {t("school.years")}
                            </div>
                            <div className="saved-school-title">
                              {item.name}
                            </div>
                            <div className="saved-school-text">
                              {item.regionResolved} · {item.settlement}
                            </div>
                          </div>
                          <div className="saved-school-text school-levels">
                            {lang === "ru" || lang === "uk" ? (
                              <>
                                {item.educationalProgramsResolved &&
                                item.educationalProgramsResolved.split(", ")
                                  .length > 3
                                  ? item.educationalProgramsResolved.split(
                                      ", "
                                    )[0] +
                                    ", " +
                                    item.educationalProgramsResolved.split(
                                      ", "
                                    )[1] +
                                    ", " +
                                    item.educationalProgramsResolved.split(
                                      ", "
                                    )[2] +
                                    " " +
                                    t("search.andMore1") +
                                    " " +
                                    t("search.andMore2") +
                                    " " +
                                    processEdProgrammes(item)
                                  : item.educationalProgramsResolved}
                              </>
                            ) : (
                              <>
                                {item.educationalProgramsResolved &&
                                item.educationalProgramsResolved.split(", ")
                                  .length > 3
                                  ? item.educationalProgramsResolved.split(
                                      ", "
                                    )[0] +
                                    ", " +
                                    item.educationalProgramsResolved.split(
                                      ", "
                                    )[1] +
                                    ", " +
                                    item.educationalProgramsResolved.split(
                                      ", "
                                    )[2] +
                                    " " +
                                    t("search.andMore1") +
                                    " " +
                                    item.educationalProgramsResolved
                                      .split(", ")
                                      .splice(0, 3).length +
                                    " " +
                                    t("search.andMore2")
                                  : item.educationalProgramsResolved}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Link>
                    <div className="school-comparison-price price1">
                      <DeleteIcon
                        onClick={() => handleRemoveItemSummer(item, index)}
                        className="delete-icon"
                      />

                      {item.perTermMin && item.perTermMin > 0 ? (
                        <div className="saved-school-price">
                          £{addSpaceNumber(item.perTermMin)} –{" "}
                          {addSpaceNumber(item.perTermMax)}
                        </div>
                      ) : null}

                      {item.weeklyFeeMin && item.weeklyFeeMin > 0
                        ? resolveSummerSchoolFees(item)
                        : null}
                    </div>
                  </div>
                )
              })}
          </div>

          <div className="map-view-wrapper col-12 col-xxl-6">
            <div className="map-frame-wrapper d-flex justify-content-center">
              <AppMap width={w} height={h} items={[resultSummersArray]} />
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <>
          {allItemSummerLocalstorage.length > 0 && (
            <div className="user-profile-title f-js">
              {t("main.userProfileMap.noSchoolsToCompare")}
            </div>
          )}
        </>
      )
    }
  }

  return (
    <div className="container">
      {allItemLocalstorage.length === 0 &&
      allItemSummerLocalstorage.length === 0 ? (
        <>
          <div className="compare-schools-wrapper no-schools">
            <div className="compare-titles f-jb">
              <div className="row mt-4">
                <div className="user-profile-title f-js d-flex align-items-center gap-4 col-12">
                  {t("main.userProfileMap.noSchoolsToCompare")}
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="f-jc">
          <div className="compare-map-wrapper">
            {allItemLocalstorage.length !== 0 && (
              <div className="compare-titles f-jb">
                <div className="row mt-4 w-100">
                  <div className="d-flex align-items-center justify-content-between col-12">
                    <div className="user-profile-title f-js ">
                      {t("main.userProfileMap.compareSchools")}
                    </div>
                    <div className="compare-location-wrapper">
                      <div className="compare-location-link-wrapper">
                        <Link to={`/${lang}/profile/compare-schools`}>
                          <div className="compare-location-link">
                            {t("main.userProfileMap.listView")}
                          </div>
                        </Link>
                      </div>
                      <ListIcon className="list-view-icon" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            {checkSchoolsPresence()}
            {allItemSummerLocalstorage.length > 0 && (
              <div className="compare-titles f-jb">
                <div className="user-profile-title f-js mt-6">
                  {t("main.userProfileMap.compareSummerSchools")}
                </div>

                {allItemLocalstorage.length === 0 && (
                  <div className="compare-location-wrapper">
                    <div className="compare-location-link-wrapper">
                      <Link to={`/${lang}/profile/compare-schools`}>
                        <div className="compare-location-link">
                          {t("main.userProfileMap.listView")}
                        </div>
                      </Link>
                    </div>
                    <ListIcon className="list-view-icon" />
                  </div>
                )}
              </div>
            )}
            {checkSummerSchoolsPresence()}
          </div>
        </div>
      )}
    </div>
  )
}

export default UserProfileCompareSchoolsMapSection
